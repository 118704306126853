<template>
  <field-component-base>
    <template #title>
      <slot />
    </template>

    <template #field>
      <div class="control is-expanded">
        <div
          class="select is-fullwidth"
          :class="{ 'is-loading': loading }"
        >
          <select
            v-model="currentValue"
            :disabled="disabled"
          >
            <option
              v-for="option in options"
              :key="optionName ? option[optionName] : option"
              :value="optionValue ? option[optionValue] : option"
            >
              {{ optionName ? option[optionName] : option }}
            </option>
          </select>
        </div>
      </div>
    </template>
  </field-component-base>
</template>

<script>
import FieldComponentBase from '../field-component-base';

export default {
  name: 'BulmaSelect',
  components: { FieldComponentBase },
  extends: FieldComponentBase,
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    optionValue: {
      type: String,
      default: null,
    },
    optionName: {
      type: String,
      default: null,
    },
  },
  computed: {
    getOptionName() {
      return this.optionName || this.optionValue || null;
    },
    getOptionValue() {
      return this.optionValue || null;
    },
  },
};
</script>
