<template>
  <div> 
  </div>
</template>

<script>
  export default {
    name: 'documents-form',
    props: ['apiKey', 'isEdit'],

  }
  </script>
