const ApiKeyTypes = {
  // Documents (new product)
  Documents: 31,

  // Actions - Basic
  Actions: 50,

  // SharePoint - Delegated auth
  ActionsAADDelegated: 51,

  // SharePoint - App auth
  ActionsAADAppOnly: 52,

  // SharePoint - Custom credentials
  ActionsSPCreds: 53,
};

export default Object.freeze(ApiKeyTypes);
