<template>
  <div
    :class="$style.wrapper"
  >
    <div
      v-if="title"
      :class="$style.title"
    >
      {{ title }}
    </div>
    <b-slider
      ref="slider"
      v-model="quantity"
      :min="min"
      :max="max"
      :step="step === null ? min : step"
      size="is-small"
      rounded
      :tooltip="false"
      @dragstart="focusThumb"
    />
  </div>
</template>
<script>
export default {
  name: 'InputRange',
  props: {
    value: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    step: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },

  computed: {
    quantity: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:value', value);
      },
    },

  },

  methods: {
    focusThumb() {
      const thumb = this.$refs.slider.$el.querySelector('.b-slider-thumb');
      thumb.focus();
    },

  },
};
</script>
<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;

  :global(.b-slider) {
    margin: 0;

    &:global(.is-small) {
      :global(.b-slider-thumb) {
        width: 20px;
        height: 20px;
        background-color: var(--current-color);
        cursor: pointer;

        &:focus,
        &:focus-visible {
          border-color: #fff;
          border-width: 2px;
          transform: none;
          outline: 2px solid #4A4A4A;
          outline-offset: -1px;
      }

        &::after,&::before {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          z-index: 38;
        }

        &::before {
          content: attr(aria-valuenow);
          padding: 0.35rem 0.75rem;
          border-radius: 6px;
          top: calc(100% + 5px);
          font-size: 0.85rem;
          color: #fff;
          background-color: var(--current-color);
          box-shadow: 0px 1px 2px 1px rgba(0, 1, 0, 0.2);
        }

        &::after {
          content: '';
          position: absolute;
          top: 100%;
          border-bottom: 6px solid var(--current-color);
          border-right: 6px solid transparent;
          border-left: 6px solid transparent;
        }
      }

      :global(.b-slider-track) {
        height: 1.5rem;
        border-top: 10px solid var(--color-white);
        border-bottom: 10px solid var(--color-white);
      }
    }

    :global(.b-slider-thumb-wrapper.is-dragging .b-slider-thumb) {
      transform: none;
      cursor: pointer;
    }
  }
}
.title {
  margin-bottom: 13px;
  font-weight: 600;
}
</style>
