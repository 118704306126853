<template>
  <field-component-base :title-class="[$style.title, { [$style.title_disabled]: disabled }]">
    <template #title>
      <slot />
    </template>

    <template #field>
      <div :class="$style.field">
        <input
          :id="switchId"
          v-model="currentValue"
          class="switch is-rounded"
          :class="{ [optionalSwitchClass]: optionalSwitchClass }"
          type="checkbox"
          :true-value="trueValue"
          :false-value="falseValue"
          :disabled="disabled"
        >

        <label
          :for="switchId"
          :class="$style.label"
        >
          {{ getLabel }}
        </label>

        <slot name="caption" />
      </div>
    </template>
  </field-component-base>
</template>

<script>
import FieldComponentBase from '../field-component-base';

export default {
  name: 'BulmaSwitch',
  components: { FieldComponentBase },
  extends: FieldComponentBase,
  props: {
    switchId: {
      type: String,
      default: 'switch',
    },

    showCaptions: {
      type: Boolean,
      default: true,
    },
    captions: {
      type: Array,
      default: () => ['on', 'off'],
    },

    optionalSwitchClass: {
      type: [String, null],
      default: null,
    },

    label: {
      type: [String, null],
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    trueValue() { return this.captions[0]; },
    falseValue() { return this.captions[1]; },
    getLabel() {
      if (this.showCaptions) {
        return this.currentValue;
      }
      if (this.label !== null) {
        return this.label;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" module>
.title {
  &_disabled {
    opacity: 0.5;
  }

  &:not(:last-child) {
    margin-bottom: 0;
  }
}

.field {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.label:not(:last-child) {
  margin-right: 4px;
}
</style>
