<template>
  <div
    :class="additionalClass"
    class="field"
  >
    <slot />
    <p
      v-for="(error, index) in errors"
      :key="index"
      class="help is-danger"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ValidationField',

  props: {
    model: {
      type: Object,
      default: () => ({
        $error: false,
      }),
    },
    validators: {
      type: Map,
      default: () => new Map([]),
    },
    enabled: {
      type: Boolean,
      default: false,
    },

    additionalClass: {
      type: String,
      default: '',
    },
  },

  computed: {
    errors() {
      if (!this.model.$error || !this.enabled) {
        return [];
      }
      const array = [];

      this.validators.forEach((message, key) => {
        if (!this.model[key]) {
          array.push(message);
        }
      });

      return array;
    },
  },
};
</script>
