<template>
  <field-component-base>
    <template #title>
      <slot />
    </template>

    <template #field>
      <input
        v-model="currentValue"
        class="input"
        type="text"
        :disabled="disabled"
        :class="{ 'is-danger': error }"
      >
    </template>
  </field-component-base>
</template>

<script>
import FieldComponentBase from '../field-component-base.vue';

export default {
  name: 'BulmaTextInput',
  components: { FieldComponentBase },
  extends: FieldComponentBase,
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
