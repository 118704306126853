<template>
  <div>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <article class="tile is-child">
          <div class="content">
            <p>
              You need API access keys to authenticate your REST and Power Automate calls.
              Create a new key and use "Secret" value from the column below.
            </p>
          </div>

          <div class="buttons">
            <a
              class="button is-primary"
              @click="add"
            >
              <span class="icon">
                <font-awesome-icon :icon="['fal', 'plus']" />
              </span>
              <span>Add key</span>
            </a>
          </div>
          <div
            v-if="keysIsVisible"
            :class="$style.table"
          >
            <b-table
              :data="apiKeys"
              :row-class="() => $style.row"
              hoverable
            >
              <b-table-column
                v-slot="{ row: { Name }, index }"
                label="Name"
                width="35%"
              >
                <div
                  :class="$style.contentWrapper"
                  @click="edit(index)"
                >
                  <div :class="$style.content">
                    <span
                      title="Edit"
                      :class="$style.edit"
                    >
                      {{ Name }}
                    </span>
                  </div>
                </div>
              </b-table-column>

              <b-table-column
                v-slot="{ row: { IsExperimental }, index }"
                label="API mode"
                width="120px"
              >
                <div
                  :class="$style.contentWrapper"
                  @click="edit(index)"
                >
                  <div :class="$style.content">
                    <span
                      title="Api mode"
                    >
                      {{ IsExperimental ? 'Testing' : 'Production' }}
                    </span>
                  </div>
                </div>
              </b-table-column>

              <b-table-column
                v-slot="{ row: { SecretId } }"
                label="Secret"
              >
                <div :class="$style.contentWrapper">
                  <div
                    :class="$style.content"
                    title="Copy"
                    @click="copy(SecretId)"
                  >
                    <span :class="$style.secret">
                      {{ SecretId }}
                    </span>
                    <b-tooltip
                      :ref="`${SecretId}-tooltip`"
                      label="Copied!"
                      :triggers="[]"
                    >
                      <font-awesome-icon :icon="['fal', 'copy']" />
                    </b-tooltip>
                  </div>
                </div>
              </b-table-column>

              <b-table-column
                v-slot="{ row: { Name }, index }"
                :cell-class="$style.delete"
                width="5%"
              >
                <button
                  type="button"
                  title="Delete"
                  :class="$style.btn"
                  @click="remove(index, Name)"
                >
                  <span
                    :class="['icon', $style.icon]"
                  >
                    <font-awesome-icon :icon="['fal', 'trash-can']" />
                  </span>
                </button>
              </b-table-column>
            </b-table>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import Confirm from '@/components/modals/confirm.vue';

export default {
  name: 'KeysTable',

  props: {
    apiKeys: {
      type: Array,
      required: true,
    },
  },

  computed: {
    keysIsVisible() {
      return this.apiKeys && this.apiKeys.length > 0;
    },
  },

  methods: {
    add() {
      this.$emit('add-key');
    },

    edit(index) {
      this.$emit('edit-key', index);
    },

    remove(index, key) {
      this.$modal.show(
        Confirm,
        {
          message: `Are you sure you want to delete the key "${key}"?`,
          submitBtnText: 'Delete',
          closeBtnText: 'Cancel',
          submitBtnType: 'is-danger',
          onSubmit: () => {
            this.$emit('remove-key', index);
          },
        },
        {
          width: '440px', height: 'auto', adaptive: true, draggable: '.modal-handler',
        },
      );
    },

    copy(text) {
      // IE
      if (window.clipboardData != null) {
        window.clipboardData.setData('Text', text);
      } else {
        // Other
        const $temp = document.createElement('input');
        document.body.append($temp);
        $temp.value = text;
        $temp.select();
        document.execCommand('copy');
        $temp.remove();
      }
      this.$refs[`${text}-tooltip`].open();
      setTimeout(() => {
        this.$refs[`${text}-tooltip`].close();
      }, 500);
    },
  },
};
</script>

<style>
.is-notification {
    background-color: #ce2d7d;
    color:#fff;
}

</style>

<style lang="scss" module>

.table {
  td {
    position: relative;
  }
}

.delete:not([align]) {
  text-align: right;
}

.btn {
  background-color: transparent;
  padding: 0;
  border: none;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
}

.contentWrapper {
  position: absolute;
  inset: 0;
}

.content {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
}

.secret,
.edit {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.secret {
  margin-right: 5px;
}

@media (max-width: 768px) {
  .table {
    td {
      position: static;
    }

    tr {
      position: relative;
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
  }
  .content {
    padding-left: 70px;
    justify-content: flex-end;
    height: auto;
  }

  [data-label="Name"] {
    .contentWrapper {
      justify-content: flex-start;
    }
  }

  [data-label="Secret"] {
    .contentWrapper {
      justify-content: flex-end;
    }
  }

  [data-label="Api mode"] {
    .contentWrapper {
      justify-content: center;
    }
  }
  .edit {
    &::after {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        content: '';
        z-index: 99;
      };
  }

  .delete {
    display: none !important;
  }
}
</style>
