export default class ApiKey {
    constructor(id = '', name = '', type = 31, secretId = '', aadTenant = '', isExperimental = false) {
        this.Id = id;
        this.Name = name;
        this.Type = type;
        this.SecretId = secretId;
        this.AADTenant = aadTenant;
        this.SPLogin = '';
        this.SPPassword = '';
        this.IsExperimental = isExperimental;
    }
}
