<template>
  <div>
    <label
      :class="titleClass"
      class="label level is-mobile"
    >
      <slot name="title" />
    </label>
    <slot name="field" />
  </div>
</template>
<script>

export default {
  name: 'FieldComponentBase',
  props: {
    value: { },
    error: {
      type: Boolean,
      default: false,
    },
    titleClass: {
      type: [String, Object, Array],
      default: '',
    },
  },

  data() { return { currentValue: undefined }; },

  watch: {
    value(newValue) {
      this.currentValue = newValue;
    },
    currentValue(newValue, oldValue) {
      if (newValue !== oldValue && oldValue !== undefined) {
        this.$emit('input', newValue);
      }
    },
  },

  mounted() {
    this.currentValue = this.value;
  },
};
</script>
