<template>
  <div>
    <keys-table
      v-show="selectedKey === null"
      :api-keys="apiKeys"
      @add-key="addKey"
      @edit-key="edit"
      @remove-key="remove"
    />

    <key-form
      v-if="selectedKey"
      :api-key="selectedKey"
      :index-key="indexKey"
      @create="create"
      @save="save"
      @cancel="cancel"
      @remove-key="remove"
    />
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useApiKeysStore, useHelpStore } from '@/stores/index.js';
import KeysTable from './keys-table.vue';
import KeyForm from './key-form.vue';
import ApiKey from './api-key.js';

export default {
  name: 'ApiKeys',
  components: { KeysTable, KeyForm },
  data() {
    return {
      selectedKey: null,
      indexKey: null,
      apiKeys: [],
    };
  },
  async mounted() {
    this.setProduct('documents');

    const { data } = await this.getKeys();

    data.forEach((key) => {
      this.apiKeys.push(new ApiKey(key.id, key.name, key.type, key.secretId, key.aadTenant, key.isExperimental));
    });

    this.setHelpOpen(true);
  },
  beforeDestroy() {
    this.setHelpOpen(false);
  },
  methods: {
    ...mapActions(useHelpStore, { setHelpOpen: 'setOpen' }),
    ...mapActions(useApiKeysStore, [
      'setProduct',
      'removeKey',
      'createKey',
      'editKey',
      'getKeys',
    ]),

    addKey() {
      this.selectedKey = new ApiKey();
    },

    edit(index) {
      this.selectedKey = { ...this.apiKeys[index] };
      this.indexKey = index;
    },

    async remove(index) {
      await this.removeKey({ id: this.apiKeys[index].Id });
      this.apiKeys.splice(index, 1);
    },

    async create() {
      const { data } = await this.createKey({ apiKey: this.selectedKey });
      this.apiKeys.push(new ApiKey(data.id, data.name, data.type, data.secretId, data.aadTenant, data.isExperimental));
      this.cancel();
    },

    async save() {
      const { data } = await this.editKey({ apiKey: this.selectedKey });

      const item = this.apiKeys.find((i) => i.Id.toLowerCase() === data.id.toLowerCase());
      const index = this.apiKeys.indexOf(item);
      const newKey = new ApiKey(data.id, data.name, data.type, data.secretId, data.aadTenant, data.isExperimental);

      if (index !== -1) this.apiKeys.splice(index, 1, newKey);
      else this.apiKeys.push(newKey);
      this.cancel();
    },

    cancel() {
      this.selectedKey = null;
      this.indexKey = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-responsive {
  display: block;
  width: 100%;
  min-height: .01%;
  overflow-x: auto;
}
</style>
