<template>
  <div
    class="tile is-ancestor"
    @keyup.enter="save"
    @keyup.esc="cancel"
  >
    <div class="tile is-parent">
      <article class="tile is-child">
        <h4
          v-if="!IsEditMode"
          class="title is-5"
        >
          Add a new key
        </h4>
        <h4
          v-if="IsEditMode"
          class="title is-5"
        >
          Edit the key
        </h4>

        <div class="columns">
          <div :class="['column', 'area', $style.area]">
            <div :class="[$style.control, 'control']">
              <label class="label">Name</label>
              <div :class="[$style.control, 'control']">
                <input
                  v-model="apiKey.Name"
                  class="input"
                  type="text"
                  placeholder="Name or description of the key"
                >
              </div>
            </div>

            <div v-if="!IsEditMode">
              <documents-form
                v-if="apiKey.Type == 31 || apiKey.Type == 0"
                :api-key="apiKey"
              />
            </div>
          </div>
        </div>
        
        <div v-if="!IsEditMode">
          <bulma-select
            v-model="mode"
            :options="modes"
            :class="[$style.subject]"
          >
            API mode
          </bulma-select>

          <div>
            <article class="message" :class="$style.info">
              <div class="message-body" v-if="mode === 'Testing'">
                <div>Free action runs with a watermark inside a result document. This mode only works for actions producing a document like DOCX, XLSX, PPTX, or PDF.</div> 
                <div class="mt-3">
                  Review <a href="https://plumsail.com/docs/documents/v1.x/user-guide/api-keys.html#test-supported-actions" target="_blank" style="color: #7d4bcd;">
                           the full list of supported actions.
                        </a>
                </div>
              </div>
              <div class="message-body" v-else>
                <span>You will be charged for action runs.</span> 
              </div>
            </article>
          </div>
        </div>

        <!-- controls -->
        <div :class="['buttons', $style.buttons]">
          <button
            :class="[$style.button, 'button', 'is-primary']"
            :disabled="!IsFormValid"
            @click="save"
          >
            {{ IsEditMode ? 'Save' : 'Create ' }}
          </button>
          <button
            :class="[$style.button, 'button', 'is-light']"
            @click="cancel"
          >
            Cancel
          </button>

          <button
            v-if="IsEditMode"
            :class="[$style.button, $style['button--right'], 'button', 'is-white']"
            @click="remove"
          >
            <span :class="$style.button__icon">
              <font-awesome-icon :icon="['fal', 'trash-can']" />
            </span>
            Delete
          </button>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import documents from './forms/documents';
import Confirm from '@/components/modals/confirm.vue';
import ApiKeyTypes from '../../../data/common/api-key-types';
import { BulmaSelectComponent } from '@/components/basic/index.js';
import { apiEndpoint } from '@/utils/url-manager.js';

function isEmpty(str) {
  return (!str || str.length === 0);
}

export default {
  name: 'KeyForm',

  components: {
    'documents-form': documents,
    'bulma-select': BulmaSelectComponent
  },
  props: {
    apiKey: {
      type: Object,
      required: true,
    },
    indexKey: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      license: null,
      ApiKeyTypes,
      modes: ['Production', 'Testing'],
      mode: 'Production',
    };
  },

  computed: {
    IsEditMode() {
      return !isEmpty(this.apiKey.Id);
    },
    IsFormValid() {
      if (this.IsEditMode) return true;

      if (this.apiKey.Type !== this.ApiKeyTypes.Documents) {
        return false;
      }

      return true;
    }
  },

  async created() {
    const { data } = await axios.get(`${apiEndpoint}documents/license`);
    this.license = data;
    this.apiKey.Type = this.ApiKeyTypes.Documents;
  },

  methods: {
    async AADOpenConsentDialog() {
      let domain = this.apiKey.AADTenant
        .replace(/^https?:\/\//, '')
        .split('/')[0]
        .split('.sharepoint.com')[0]
        .trim();

      domain += '.sharepoint.com';
      const source = window.location.href;

      try {
        const appId = 'b49eeb3b-7128-44b0-9794-1b0c2bd030f7';
        const options = { showProgressBar: false };
        const { data } = await this.axios.post(
          `${apiEndpoint}actions/state?domain=${encodeURIComponent(domain)}&source=${encodeURIComponent(source)}`,
          this.apiKey,
          options,
        );
        const { state } = data;
        const redirectUri = `${apiEndpoint}actions/consent`;
        const authority = domain.replace('sharepoint.com', 'onmicrosoft.com');
        const link = `https://login.microsoftonline.com/${authority}/adminconsent?client_id=${appId}&state=${state}&redirect_uri=${redirectUri}&prompt=admin_consent`;

        window.location = link;
      } catch {
        window.alert('The specified domain name was not found');
      }
    },

    save() {
      if (this.IsEditMode) {
        this.$emit('save');
        return;
      }

      this.apiKey.IsExperimental = this.mode === 'Testing'

      if (this.apiKey.Type === '10') {
        this.AADOpenConsentDialog();
      } else {
        this.$emit('create');
      }
    },

    cancel() {
      this.$emit('cancel');
    },

    remove() {
      this.$modal.show(
        Confirm,
        {
          message: 'Are you sure you want to delete this key?',
          submitBtnText: 'Delete',
          closeBtnText: 'Cancel',
          submitBtnType: 'is-danger',
          onSubmit: () => {
            this.$emit('remove-key', this.indexKey);
            this.$emit('cancel');
          },
        },
        {
          width: '440px', height: 'auto', adaptive: true, draggable: '.modal-handler',
        },
      );
    },
  },
};

</script>

<style lang="scss" module>
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";

  .area {
    max-width: 600px;
  }

  .subject {
    max-width: 576px;
    margin-bottom: 15px;
    margin-top: -12px;
  }

  .buttons {
    max-width: 576px;
    .button {
      margin: 5px 0 0;

      &--right {
        margin-left: auto;

      }

      &__icon {
        margin-right: 10px;
      }
    }
  }

  .control .button {
    margin: inherit;
  }

  .control.has-addons {
    @include mobile() {
      input {
        width: 100%;
      }

      input.is-expanded {
        flex-shrink: 1;
      }
    }
  }

  .info {
        max-width: 576px;
        margin-bottom: 10px;
    }

</style>